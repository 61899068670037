a,
a:link,
a:visited {}

.x_1_work_place .x_1_work_place a,
.x_1_work_place a:link,
.x_1_work_place a:visited,
.x_1_work_place a:hover,
.pr_link,
.pr_link a,
.pr_link a:link,
.pr_link a:visited,
.pr_link a:hover,
.pr_info {
    color: currentColor;
    border-color: currentColor !important;
}